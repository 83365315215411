<template>
    <div :class="active?'keyBox active':'keyBox'" @mousedown.prevent="handleMousedown">
		<div class="keyText" :style="{fontSize: keyName&&keyName.length>1?'1.48vh':'2vh'}">{{keyName[0]}}</div>
		<div class="keyText" style="font-size: 1.48vh" v-if="keyName&&keyName.length>1">{{keyName[1]}}</div>
	</div>
</template>

<style lang="less" scoped>
.keyBox {
	padding: 0.74vh;
	cursor: pointer;
	background: #000;
	border-radius: 0.74vh;
	border: #000 0.5px solid;

	.keyText {
		color: #fff;
		line-height: 1.74vh;

	}
}
.active {
	background: #fff;
	.keyText {
		color: #000;
		line-height: 1.74vh
	}
}
.keyBox:hover {
	opacity: 0.65
}
.keyBox:active {
	background: #fff;

	.keyText {
		color: #000;
		line-height: 1.74vh
	}
}
</style>

<script>
export default {
	props: ['keyName', 'active'],
	data(){
		return {
			
		}
	},
	mounted(){
		return false;
	},
	methods:{
		handleClick(){
			this.$emit('click');
		},
		handleMousedown(){
			this.$emit('mousedown', this.keyName);
		}
	},
	updated(){
	}
}
</script>

